import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ReactComponent as XIcon } from "../assets/X.svg";

export default function GenericModal({ open, setOpen, children }) {
  return (
    <Dialog className="relative z-50" open={open} onClose={setOpen}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center text-center items-center ">
          <div className="relative ">
            <div
              onClick={() => setOpen(false)}
              className="absolute -top-9 right-0  lg:-top-6 lg:-right-9 z-50"
            >
              <XIcon className="h-8 w-8 cursor-pointer" />
            </div>

            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg  text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in  sm:w-full sm:max-w-4xl  data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div>{children}</div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
