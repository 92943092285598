// import { useState } from "react";
// import { ReactComponent as Logo } from "./assets/MOSLOGO.svg";
// // import { ReactComponent as SpeakBtn } from "./assets/SpeakBtn.svg";
// import { ReactComponent as SpeakBtn } from "./assets/Sign lang 2.svg";

// import GenericModal from "./components/GenericModal";

// export default function App() {
//   const [openIframe, setopenIframe] = useState(false);
//   const [openModal, setopenModal] = useState(false);

//   const MyIcon = () => {
//     return (
//       <svg
//       className="opacity-80"
//         height="64px" // Adjust size of the play icon
//         width="64px"
//         viewBox="0 0 32 32"
//         xmlns="http://www.w3.org/2000/svg"
//         xmlnsXlink="http://www.w3.org/1999/xlink"
//         style={{ fill: '#4E4E50' }}
//       >
//         <g id="Layer_1" />
//         <g id="play_x5F_alt">
//           <path d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M10,24V8l16.008,8L10,24z" />
//         </g>
//       </svg>
//     );
//   };
  

//   return (
//     <div className="h-screen bg-white">
//       <div className="max-w-[80rem] mx-5 lg:mx-auto z-50">
//         <div className="flex justify-end py-7">
//           <Logo className="w-40" />
//         </div>

//         {openIframe ? (
//           <iframe
//             src="https://demo.antillia.sa/"
//             // src="https://deaf.4d.sa/"
//             className="h-[80vh] w-full"
//             allow="camera; microphone; fullscreen; autoplay; encrypted-media; geolocation"
//           />
//         ) : (
//           <div className="flex justify-center items-center min-h-[75vh] ">
//             <div dir="rtl" className="flex flex-col lg:flex-row">
//               <div>
//                 <div className="text-right space-y-5 lg:p-10 ">
//                   <h1 className="text-[#079D3D] font-medium text-3xl mb-5 ">
//                     مرحباً بكم في موقع
//                     <br /> وزارة الرياضة
//                   </h1>
//                   <h1 className="font-medium text-lg mb-5 ">
//                     هذه الخدمة مخصصة للصم وضعاف السمع.
//                   </h1>
//                       {/* يرجى الضغط على زر ابدأ التحدث الان لنتمكن من خدمتك بأفضل
//                     طريقة ممكنة. الخدمة متوفرة خلال أوقات العمل من الأحد إلى
//                     الخميس، من الساعة 10 صباحاً إلى الساعة 6 مساءً{" "} */}
//                   <p className="font-light text-md leading-loose ">
//                     يرجى الضغط أدناه على زر 
//                    <span className="font-bold"> "ابدأ المكالمة الان" </span>
//                     لنتمكن من خدمتك بأفضل طريقة ممكنة. الخدمة متوفرة خلال أوقات العمل من الأحد إلى الخميس، من الساعة 10 صباحاً إلى الساعة 6 مساءً.
//                   </p>
//                 </div>

//                 <div className="flex justify-center lg:justify-start my-5 lg:mx-5 lg:my-0 mx-auto">
//                   <SpeakBtn
//                     onClick={() => setopenIframe(true)}
//                     className="h-12 w-52 cursor-pointer"
//                   />
//                 </div>
//               </div>

//               <div
//                 className="flex items-start justify-center"
//                 onClick={() => {
//                   setopenModal(true);
//                 }}
//               >

// <div className="relative w-full h-full">

                
//                 <video
//         muted
//         // controls
//         className="lg:p-1 w-full h-full object-cover rounded-3xl"
//       >
//         <source src="./MOS - Vd.02.mp4" type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>

//       {/* Play Icon */}
//       <div className="absolute inset-0 flex justify-center items-center">
//         <MyIcon />
//       </div>
//       </div>

//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       <GenericModal open={openModal} setOpen={setopenModal}>
//         <video
//           muted="true"
//           autoPlay
//           controls
//           className="w-full h-full object-cover "
//         >
//           <source src="./MOS - Vd.02.mp4" type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       </GenericModal>
//     </div>
//   );
// }




















// import { useState, useEffect } from "react";
// import { ReactComponent as Logo } from "./assets/MOSLOGO.svg";
// import { ReactComponent as SpeakBtn } from "./assets/Sign lang 2.svg";
// import GenericModal from "./components/GenericModal";

// export default function App() {
//   const [openIframe, setopenIframe] = useState(false);
//   const [openModal, setopenModal] = useState(false);

//   const MyIcon = () => {
//     return (
//       <svg
//         className="opacity-80"
//         height="64px"
//         width="64px"
//         viewBox="0 0 32 32"
//         xmlns="http://www.w3.org/2000/svg"
//         xmlnsXlink="http://www.w3.org/1999/xlink"
//         style={{ fill: '#4E4E50' }}
//       >
//         <g id="Layer_1" />
//         <g id="play_x5F_alt">
//           <path d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M10,24V8l16.008,8L10,24z" />
//         </g>
//       </svg>
//     );
//   };

//   // Function to automatically click the Zoom floating button after it appears
//   const autoClickZoomButton = () => {
//     const observer = new MutationObserver((mutations) => {
//       mutations.forEach((mutation) => {
//         // Check for the class of the floating button
//         const zoomButton = document.querySelector(".livesdk__invitation");
//         if (zoomButton) {
//           zoomButton.click(); // Simulate a click event
//           observer.disconnect(); // Stop observing once clicked
//         }
//       });
//     });

//     // Observe changes to the body for added elements
//     observer.observe(document.body, { childList: true, subtree: true });
//   };

//   // Function to listen for the "Leave" button and refresh the page
//   const handleLeaveButton = () => {
//     const observer = new MutationObserver((mutations) => {
//       mutations.forEach(() => {
//         const leaveButton = document.querySelector('button[aria-label="Leave"]');
//         if (leaveButton) {
//           leaveButton.addEventListener('click', () => {
//             window.location.reload(); // Refresh the page when the button is clicked
//           });
//           observer.disconnect(); // Stop observing once the button is found and listener is attached
//         }
//       });
//     });

//     // Observe changes to the body for added elements
//     observer.observe(document.body, { childList: true, subtree: true });
//   };

//   // Use effect to inject the Zoom video script when openIframe is true
//   useEffect(() => {
//     if (openIframe) {
//       const zcczvaScript = document.createElement("script");
//       zcczvaScript.setAttribute("src", "https://us01ccistatic.zoom.us/us01cci/web-sdk/zcc-sdk.js");
//       zcczvaScript.setAttribute("data-apikey", "nJp4BwTKTbinYCrdKPNmCQ");
//       zcczvaScript.setAttribute("data-entry-id", "qnUIqQplSXqcJJpLIlXqbA");
//       document.body.appendChild(zcczvaScript);
//       // zcczvaScript.startVideo()

//       autoClickZoomButton(); // Automatically click the button once it's loaded
//       handleLeaveButton();   // Attach the refresh event listener to the "Leave" button
//     }
//   }, [openIframe]);
//   return (
//     <div className="h-screen bg-white">
//       <div className="max-w-[80rem] mx-5 lg:mx-auto z-50">
//         <div className="flex justify-end py-7">
//           <Logo className="w-40" />
//         </div>

//         {openIframe ? (
//           // Replaced iframe with script-injected Zoom conference
//           <div className="h-[80vh] w-full" id="zoom-container"></div>
//         ) : (
//           <div className="flex justify-center items-center min-h-[75vh] ">
//             <div dir="rtl" className="flex flex-col lg:flex-row">
//               <div>
//                 <div className="text-right space-y-5 lg:p-10 ">
//                   <h1 className="text-[#079D3D] font-medium text-3xl mb-5 ">
//                     مرحباً بكم في موقع
//                     <br /> وزارة الرياضة
//                   </h1>
//                   <h1 className="font-medium text-lg mb-5 ">
//                     هذه الخدمة مخصصة للصم وضعاف السمع.
//                   </h1>
//                   <p className="font-light text-md leading-loose ">
//                     يرجى الضغط أدناه على زر 
//                     <span className="font-bold"> "ابدأ المكالمة الان" </span>
//                     لنتمكن من خدمتك بأفضل طريقة ممكنة. الخدمة متوفرة خلال أوقات العمل من الأحد إلى الخميس، من الساعة 10 صباحاً إلى الساعة 6 مساءً.
//                   </p>
//                 </div>

//                 <div className="flex justify-center lg:justify-start my-5 lg:mx-5 lg:my-0 mx-auto">
//                   <SpeakBtn
//                     onClick={() => setopenIframe(true)}
//                     className="h-12 w-52 cursor-pointer"
//                   />
//                 </div>
//               </div>

//               <div
//                 className="flex items-start justify-center"
//                 onClick={() => {
//                   setopenModal(true);
//                 }}
//               >
//                 <div className="relative w-full h-full">
//                   <video
//                     muted
//                     className="lg:p-1 w-full h-full object-cover rounded-3xl"
//                   >
//                     <source src="./MOS - Vd.02.mp4" type="video/mp4" />
//                     Your browser does not support the video tag.
//                   </video>

//                   <div className="absolute inset-0 flex justify-center items-center">
//                     <MyIcon />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       <GenericModal open={openModal} setOpen={setopenModal}>
//         <video
//           muted="true"
//           autoPlay
//           controls
//           className="w-full h-full object-cover"
//         >
//           <source src="./MOS - Vd.02.mp4" type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       </GenericModal>
//     </div>
//   );
// }




























import { useState,useEffect } from "react";
import { ReactComponent as Logo } from "./assets/MOSLOGO.svg";
import { ReactComponent as SpeakBtn } from "./assets/Sign lang 2.svg";
import GenericModal from "./components/GenericModal";

export default function App() {
  const [openIframe, setopenIframe] = useState(false);
  const [openModal, setopenModal] = useState(false);

  const MyIcon = () => {
    return (
      <svg
        className="opacity-80"
        height="64px"
        width="64px"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ fill: '#4E4E50' }}
      >
        <g id="Layer_1" />
        <g id="play_x5F_alt">
          <path d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M10,24V8l16.008,8L10,24z" />
        </g>
      </svg>
    );
  };

   // Use useEffect to handle messages from the iframe
   useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.event) {
        // Check for specific event and handle redirection
        if (event.data.event === 'sessionEnded' || event.data.event === 'videoEnded' || event.data.event === 'videoLeft') {
          window.location.href = "https://mos.labeah.ai/";
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div className="h-screen bg-white">
      <div className="max-w-[80rem] mx-5 lg:mx-auto z-50">
        <div className="flex justify-end py-7">
          <Logo className="w-40" />
        </div>

        {openIframe ? (
          <iframe
            src="/zoom.html"  // Path to the Zoom SDK HTML file
            className="h-[80vh] w-full"
            allow="camera; microphone; fullscreen; autoplay; encrypted-media; geolocation"
          />
        ) : (
          <div className="flex justify-center items-center min-h-[75vh] ">
            <div dir="rtl" className="flex flex-col lg:flex-row">
              <div>
                <div className="text-right space-y-5 lg:p-10 ">
                  <h1 className="text-[#079D3D] font-medium text-3xl mb-5 ">
                    مرحباً بكم في موقع
                    <br /> وزارة الرياضة
                  </h1>
                  <h1 className="font-medium text-lg mb-5 ">
                    هذه الخدمة مخصصة للصم وضعاف السمع.
                  </h1>
                  <p className="font-light text-md leading-loose ">
                    يرجى الضغط أدناه على زر 
                    <span className="font-bold"> "ابدأ المكالمة الان" </span>
                    لنتمكن من خدمتك بأفضل طريقة ممكنة. الخدمة متوفرة خلال أوقات العمل من الأحد إلى الخميس، من الساعة 10 صباحاً إلى الساعة 6 مساءً.
                  </p>
                </div>

                <div className="flex justify-center lg:justify-start my-5 lg:mx-5 lg:my-0 mx-auto">
                  <SpeakBtn
                    onClick={() => setopenIframe(true)}
                    className="h-12 w-52 cursor-pointer"
                  />
                </div>
              </div>

              <div
                className="flex items-start justify-center"
                onClick={() => {
                  setopenModal(true);
                }}
              >
                <div className="relative w-full h-full">
                  <video
                    muted
                    className="lg:p-1 w-full h-full object-cover rounded-3xl"
                  >
                    <source src="./MOS - Vd.02.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                  <div className="absolute inset-0 flex justify-center items-center">
                    <MyIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <GenericModal open={openModal} setOpen={setopenModal}>
        <video
          muted="true"
          autoPlay
          controls
          className="w-full h-full object-cover "
        >
          <source src="./MOS - Vd.02.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </GenericModal>
    </div>
  );
}

